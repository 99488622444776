import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Carousel extends React.Component {
  static propTypes = {
    duration: PropTypes.number,
    height: PropTypes.number,
  }

  static defaultProps = {
    duration: 7000,
    height: 500,
  }

  state = {
    currentIndex: 0,
    navigated: false,
  };

  componentDidMount() {
    const { duration } = this.props;
    const { navigated } = this.state;

    // Auto-cycle every duration seconds unless navigation has occurred
    this.intervalId = setInterval(() => {
      if (!navigated) {
        this.goToNext();
      }
    }, duration);
  }

  componentWillUnmount() {
    // Clean up the interval when the component is unmounted
    clearInterval(this.intervalId);
  }

  goTo = (i) => {
    clearInterval(this.intervalId);
    this.setState({
      currentIndex: i,
      navigated: true,
    });
  }

  goToNext = () => {
    const { currentIndex } = this.state;
    const { children } = this.props;
    this.setState({
      currentIndex: (currentIndex + 1) % children.length,
    });
  };

  goToPrevious = () => {
    const { currentIndex } = this.state;
    const { children } = this.props;
    this.setState({
      currentIndex: (currentIndex - 1 + children.length) % children.length,
    });
  };

  handleNextClick = () => {
    this.setState({
      navigated: true
    }, () => this.goToNext());
  };

  handlePrevClick = () => {
    this.setState({
      navigated: true
    }, () => this.goToPrevious());
  };

  render() {
    const { children, height } = this.props;
    const { currentIndex } = this.state;

    return (
      <div className={`carousel height-${height}`}>
        <div className="carousel-content">
          {
            children.map((child, i) => (
              <div {...{
                key: i,
                  className: classNames('carousel-item', {
                    'fade-in': i === currentIndex,
                    'fade-out': i != currentIndex,
                  }),
              }}>{child}</div>
            ))
          }
        </div>
        <div className="carousel-nav">
          {
            children.length > 0 &&
            <React.Fragment>
              {
                children.map((_, i) => (
                  <div {...{
                    key: `child-${i}`,
                    className: classNames({
                      'nav-selected': i === currentIndex,
                      'nav-item': i != currentIndex,
                     }),
                    onClick: () => this.goTo(i),
                  }}></div>
                ))
              }
            </React.Fragment>
          }
        </div>
      </div>
    );
  }
}