import React from "react";
import PropTypes from 'prop-types';

// Base
import Avatar from "../../../base/avatar";

export default class Testimonial extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    profileImageUrl: PropTypes.string.isRequired,
  }

  render() {
    const { text, name, title, profileImageUrl } = this.props;

    return (
      <div>
        <div className="font-size-22 line-height-42 tertiary-color margin-bottom-35">
          <span className="xlg-font">"</span>
          {text}
          <span className="xlg-font">"</span>
        </div>
        <div className="flex flex-row height-55">
          <div>
            <Avatar {...{
              src: profileImageUrl,
              className: 'width-55 height-55',
            }} />
          </div>
          <div className="flex flex-col justify-space-between padding-left-20 margin-top-7 margin-bottom-7">
            <div className="accent-color xsm-font">{name}</div>
            <div className="xxsm-font tertiary-color">{title}</div>
          </div>
        </div>
      </div>
    )
  }
}