import React from 'react';

// Base
import Carousel from '../../base/carousel';

// Related Components
import Testimonial from './testimonials/testimonial';

export default class Testimonials extends React.Component {
  render() {
    return (
      <div className='max-width-400 margin-center margin-top-195'>
        <Carousel>
          <Testimonial {...{
            text: "Oomple's innovative platform has a more positive and beneficial approach to both consultants and agencies. We're able to really leverage the platform to meet our complex hiring needs.",
            name: "Michael Giguere",
            title: "Senior Director | MaxSys",
            profileImageUrl: `${gon.HUBSPOT_ASSETS_URL}/oomple/assets/app/v5/testimonials/michael.png`,
          }} />
          <Testimonial {...{
            text: "Oomple made life easy when it came to hiring, the platform provided a wide selection of ready - now candidates, simplifying the recruitment process and enabling Talasoft to focus on its core mission.",
            name: "Talal Eid",
            title: "Co - Founder and COO | Talasoft",
            profileImageUrl: `${gon.HUBSPOT_ASSETS_URL}/oomple/assets/app/v5/testimonials/talal.png`,
          }} />
          <Testimonial {...{
            text: "Oomple offered a comprehensive solution that combined both candidate management and sourcing capabilities. We were initially drawn to its ability to automate job postings to multiple different job boards, track candidate progress, and source potential candidates from various channels.",
            name: "Tam Nguyen",
            title: "Head of Talent | Orangutech",
            profileImageUrl: `${gon.HUBSPOT_ASSETS_URL}/oomple/assets/app/v5/testimonials/tam.png`,
          }} />
        </Carousel>
      </div>
    )
  }
}