import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Separator extends React.PureComponent {
  static propTypes = {
    isSmall: PropTypes.bool,
    center: PropTypes.bool,
    className: PropTypes.string,
  }

  static defaultProps = {
    isSmall: false,
    center: false,
  }

  get className() {
    const { isSmall, center, className } = this.props;
    return classNames('separator', {
      [className]: className,
      'is-small': isSmall,
      'is-center': center,
    });
  }

  render() {
    return <div className={this.className} />
  }
}
