import React from 'react';
import { createRoot } from 'react-dom/client';
import SignIn from '../../../../components/v5/public/users/sign_in';


const element = document.getElementById('sign-in');

document.addEventListener('DOMContentLoaded', () => {
  if (element) {
    const {
     consentFormId, email, firstName, lastName, tokenId,
    } = element.dataset;
    const root = createRoot(element);
    root.render(
      <SignIn {...{
        consentFormId: (consentFormId ? parseInt(consentFormId) : undefined),
        tokenId: (tokenId ? tokenId : ''),
        email,
        firstName,
        lastName,
      }} />
    );
  }
});

